import React from 'react'
import styles from './Project.module.css';
import { Carousel } from 'react-responsive-carousel';

const renderImages = (images = []) => {
    return images.map(image => {
        return <div>
            <img src={image} />
        </div>
    })
}
const Project = ({ title, role, description, images }) => {
    return (
        <section className={styles.section}>
            <div className={styles.project}>
                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className={styles.textBlock}>
                            <h5 class="project-title">{title}</h5>
                            <p class="project-role">{role}</p>
                            <p class="project-description">Improving the customer portal by extending it with new features and rewriting the front-end.</p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8">
                        <Carousel showArrows={false} showThumbs={false} showStatus={false}>
                            {renderImages(images)}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Project
