import React from 'react'
import styles from './Social.module.css';

const Social = props => {
    return (
        <ul className={styles.social}>
            <li><a href="http://linkedin.com/in/cedric-berlanger-07272680/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
            <li><a href="https://github.com/berlangerc" target="_blank"><i className="fab fa-github"></i></a></li>
        </ul>
    )
}

export default Social
