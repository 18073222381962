import React from 'react'
import PropTypes from 'prop-types'

const Header = props => {
    return (
        <div className="container">
            Header
        </div>
    )
}

Header.propTypes = {

}

export default Header
