import React from 'react'
import PropTypes from 'prop-types'
import styles from './Information.module.css';

const Information = props => {
    return (
        <ul className={styles.information}>
            <li>29/01/1993</li>
            <li>cedric.berlanger@outlook.com</li>
            <li>Married</li>
        </ul>
    )
}

Information.propTypes = {

}

export default Information
