import React from 'react';
import './App.css';

import Header from './Header/Header';
import Intro from './Intro/Intro';
import Projects from './Projects/Projects';
function App() {
  return (
    <div className="app">
      <Header />
      <Intro />
      <Projects />

    </div >
  );
}

export default App;
