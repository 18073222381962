import React from 'react'
import styles from './Project.module.css';
import tme_cp_sso from '../Assets/projects/tme_cp_sso2.PNG';
import Project from './Project';
import tme_cp_network_kpi from '../Assets/projects/tme_cp_network_kpi.png';

const projectsInfo = [{
    title: "TME Customer Portal",
    role: "(Lead) Full Stack agile software Engineer",
    description: "Improving the customer portal by extending it with new features and rewriting the front-end.",
    images: [tme_cp_sso]
},
{
    title: "TME Network KPI",
    role: "Full Stack agile software Engineer",
    description: "Improving the customer portal by extending it with new features and rewriting the front-end.",
    images: [tme_cp_network_kpi]
}]
const renderProjects = () => {
    return projectsInfo.map(project => {
        return <Project {...{ ...project }} />
    })
}
const Projects = () => {
    return (
        <section className="">
            <div className="container">
                <div className="row">
                    <h3 className={styles.h3}>Portfolio</h3>

                    {renderProjects()}
                </div>

            </div>

        </section>
    )
}

export default Projects
