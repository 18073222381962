import React from 'react'
import PropTypes from 'prop-types'
import styles from './Intro.module.css';
import ProfilePic from './ProfilePic';
import Information from './Information';
import Social from './Social';

const Intro = props => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className={"col-md-8 " + styles.intro}>
                    <ProfilePic />
                    <h2 className={styles.name}>Cedric Berlanger</h2>
                    <h3 className={styles.role}>Freelance Software Engineer</h3>
                    <Information />
                    <Social />
                </div>
                <div className="col-md-2"></div>
            </div>

        </div>
    )
}

Intro.propTypes = {

}

export default Intro
