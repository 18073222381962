import React from 'react'
import PropTypes from 'prop-types'
import profilePic from '../Assets/Profile_image.png';
import styles from './ProfilePic.module.css';

const ProfilePic = props => {
    return (
        <div className={styles.profileImg}>
            <img src={profilePic} />
        </div>
    )
}

ProfilePic.propTypes = {

}

export default ProfilePic
